/*globals $ */

import News from './News';
import SectionTitle from './SectionTitle';
import ScrollSection from './ScrollSection';
import FirstviewColorChanger from './FirstviewColorChanger';


import SwiperCore, { Pagination } from 'swiper/core';

// configure Swiper to use modules
SwiperCore.use([Pagination]);

// import Swiper from 'swiper';

class PagePro2 {
  constructor(mainApp) {
    this.mainApp            = mainApp;

    this.$logo              = $('.Logo');
    this.$firstview         = $('.Firstview')
    this.$hero              = $('.Hero');
    this.$bottomContents    = $('.BottomContents');

    this.sectionTitle       = new SectionTitle();
    this.hasNews            = false;
    this.isHome             = false;
    this.isStart            = false;
    this.hasIntroVideo      = false;

    if($('.TopSlideItem').length > 1) {
      // const swiper = new Swiper('.swiper-container', {
      //   pagination: {
      //     el: '.swiper-pagination',
      //     type: 'bullets',
      //     clickable: true,
      //     renderBullet:  (index, className) => {
      //       return '<button class="' + className + '"><span></span></button>';
      //     }
      //   },
      //   on: {
      //     afterInit: () => {
      //       if($('.swiper-slide-active').attr('data-invert') == 'true') {
      //         $('.swiper-pagination').addClass('swiper-pagination--invert');
      //       }
      //     },
      //     slideChangeTransitionEnd: () => {
      //       if($('.swiper-slide-active').attr('data-invert') == 'true') {
      //         $('.swiper-pagination').addClass('swiper-pagination--invert');
      //       } else if($('.swiper-slide-active').attr('data-invert') == 'false'){
      //         $('.swiper-pagination').removeClass('swiper-pagination--invert');
      //       }
      //     }
      //   }
      // });

      if($('.TopSlideItem').length < 2) {
        $('.swiper-pagination').css('display', 'none');
      }
    }


    /*
    if($('.LoadingMovie').length) {
      this.hasIntroVideo = true;
      this.$introVideo = $('.LoadingMovie');
    }
    */

    // this.logoOffsetTop      = this.$logo.offset()['top'];
    // this.logoHeight         = this.$logo.height();
    // this.logoBottomPos      = this.logoOffsetTop + this.logoHeight;

    this.logoSlideValue     = 0;
    this.sections           = [];

    if($('body').hasClass('home')) {
      this.isHome = true;

      if(this.hasIntroVideo == true) {
        var intro = this.$introVideo.get(0);
        intro.addEventListener('ended', () => {
        });
        console.log('play video');
        intro.play();
      }
    }

    if($('.Firstview--colorfade').length) {
      new FirstviewColorChanger();
    }

    if($('.NewsFooter').length) {
      this.hasNews = true;
      this.news = new News();
    }

    if(this.hasNews) {
      this.heroBottomPos = $('.NewsFooter').offset()['top'] - 30;
    } else {
      if(this.isHome) {
        this.heroBottomPos = 0;
      } else {
        this.heroOffsetTop = this.$hero.offset()['top'];
        this.heroHeight = this.$hero.outerHeight();
        this.heroBottomPos = Math.floor(this.heroOffsetTop + this.heroHeight);
      }
      // this.heroOffsetTop = this.$hero.offset()['top'];
      // this.heroHeight = this.$hero.outerHeight();
      // this.heroBottomPos = Math.floor(this.heroOffsetTop + this.heroHeight);
    }

    $('.ScrollSection').each((i, e) => {
      this.sections.push(new ScrollSection(this, $(e)));
    });
  }

  start() {
    if(this.hasNews) {
      this.news.fadein();
    } else {
      this.$hero.delay(350).animate({'opacity':1}, 600, 'easeInOutExpo');
    }

    if($('body').hasClass('page-template-page-pro-php') || $('body').hasClass('page-template-page-alpha-php')) {
      if(location.hash.length > 2) {
        let hash = String(location.hash).slice(1);
        if(hash != 'news') {
          let $targetSection = $('.ScrollSection[data-id="' + hash + '"]');
          // let targetPaddingTop = Math.floor(parseInt($targetSection.find('.ScrollSectionWindow').css('padding-top')) / 2);
          let targetPaddingTop = parseInt($targetSection.find('.ScrollSectionWindow').css('padding-top'));
          this.mainApp.scrollPage($targetSection.offset()['top'] + targetPaddingTop);
        }
      }
    }

    $('.ScrollSectionContents').css('opacity', 1);


    $('.SlideWrap').css('opacity', 1);
    // this.mainApp.logoTq.fadein();
    this.isStart = true;
  }

  onWindowScroll(scrollTop) {
    if(this.logoBottomPos > this.heroBottomPos - scrollTop) {
      this.logoSlideValue = this.logoBottomPos - (this.heroBottomPos - scrollTop);
    } else {
      this.logoSlideValue = 0;
    }

    if(this.logoSlideValue >= 0) {
      this.$logo.css('transform', 'translate3d(0, -' + this.logoSlideValue + 'px, 0)');

    } else {
      this.$logo.css('transform', 'translate3d(0, 0, 0);');
    }

    this.fadeinSection = -1;
    for(let i = 0; i < this.sections.length; i++) {
      if(this.sections[this.sections.length - 1 - i].$container.offset()['top'] < scrollTop + 10) {
        this.fadeinSection = this.sections.length - 1 - i;
        break;
      }
    }
    if(this.fadeinSection == -1) {
      $('.Background').attr('data-bg', '');
      this.mainApp.logoTq.fadeout();
    } else if(this.fadeinSection == this.sections.length - 1) {
      $('.Background').attr('data-bg', 'black');
      this.mainApp.logoTq.fadeout();
    } else {
      this.mainApp.logoTq.fadein();
    }



    if(this.fadeinSection == -1) {
      this.sectionTitle.fadeout();
      for(let i = 0; i < this.sections.length; i++) {
        this.sections[i].fadeout();
      }
    } else {
      for(let i = 0; i < this.sections.length; i++) {
        if(i == this.fadeinSection) {
          this.sections[i].fadein();
        } else {
          this.sections[i].fadeout();
        }
      }
    }


  }
  onWindowResize() {
    if(!this.isHome) {
      this.logoOffsetTop = this.$logo.offset()['top'];
      this.logoHeight = this.$logo.height();
      this.logoBottomPos = this.logoOffsetTop + this.logoHeight;
    } else {
      this.logoBottomPos = 0;
    }


    if(this.hasNews) {
      // this.heroOffsetTop = 0;//this.$hero.offset()['top'];
      // this.heroHeight = this.$hero.outerHeight();
      this.heroBottomPos = $('.NewsFooter').offset()['top'] - 30;//Math.floor(this.heroOffsetTop + this.heroHeight);
    } else {
      if(this.isHome) {
        this.heroBottomPos = 0;
      } else {
        this.heroOffsetTop = this.$hero.offset()['top'];
        this.heroHeight = this.$hero.outerHeight();
        this.heroBottomPos = Math.floor(this.heroOffsetTop + this.heroHeight);
      }
    }

    for(let i = 0; i < this.sections.length; i++) {
      this.sections[i].onWindowResize();
    }

    $('.TopSection--footer').css('height', window.innerHeight);
  }
}

export default PagePro2;
